import instance from "./AxiosInstance/unAuthenticated";
import authenticatedInstance from "./AxiosInstance/authenticated";
import { toast } from "sonner";
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithPopup,
  signInWithEmailAndPassword,
  OAuthProvider,
  sendPasswordResetEmail,
  confirmPasswordReset,
} from "firebase/auth";
import { auth } from "../../firebase-config";
import { GoogleAuthProvider } from "firebase/auth";

const googleAuthProvider = new GoogleAuthProvider();
const appleAuthprovider = new OAuthProvider("apple.com");
export const handleSignIn = async ({ email, password }) => {
  try {
    const user = await signInWithEmailAndPassword(auth, email, password);
    if (user) {
      const { data, status } = await instance.post("/user", {
        uid: user?.user?.uid,
      });
      if (status === 200) {
        if (data?.user?.subscriptionActive) {
          localStorage.setItem("accessToken", data.accessToken);
          toast.success("Logged in successfully!");
          return { success: true, redirect: "/dashboard", user: data?.user };
        } else {
          localStorage.setItem("accessToken", data.accessToken);
          toast.error("subscription not found!");
          return {
            success: true,
            redirect: "/payment-options",
            user: data?.user,
          };
        }
      } else {
        toast.error("Invalid credentials!");
        return { success: false, redirect: "/signin" };
      }
    } else {
      toast.error("Invalid credentials!");
      return { success: false, redirect: "/signin" };
    }
  } catch (error) {
    console.log(error, "Error in signin");
    toast.error("Invalid credentials!");
    return { success: false, redirect: "/signin" };
  }
};

export const handleSignUp = async ({ email, password, name }) => {
  try {
    const user = await createUserWithEmailAndPassword(auth, email, password);
    const { data, status } = await instance.post("/user/signup", {
      name: name,
      email: email.toLowerCase(),
      uid: user?.user?.uid,
    });
    if (status === 200) {
      // add this user as a lead if its a referred user
      if (window.tolt_referral) {
        window.tolt.signup(email.toLowerCase());
      }
      localStorage.setItem("accessToken", data.accessToken);
      toast.success("Signed up successfully!");
      return true;
    }
  } catch (error) {
    console.log("Error in signup", error.message);
    if (error.message === "Firebase: Error (auth/email-already-in-use).") {
      toast.error("Email is already in use!");
    } else {
      toast.error("Something went wrong!");
    }
    return false;
  }
};

export const handleGoogleAuth = async (type) => {
  try {
    const user = await signInWithPopup(auth, googleAuthProvider);
    if (type === "signup") {
      const { data, status } = await instance.post("/user/signup", {
        name: user?.user?.displayName,
        email: user?.user?.email,
        uid: user?.user?.uid,
      });
      if (status === 200) {
        localStorage.setItem("accessToken", data.accessToken);
        toast.success("Signed up successfully!");
        return { email: user?.user?.email, name: user?.user?.displayName };
      }
    } else {
      const { data, status } = await instance.post("/user", {
        uid: user?.user?.uid,
      });
      if (status === 200) {
        if (data?.user?.subscriptionActive) {
          localStorage.setItem("accessToken", data.accessToken);
          toast.success("Logged in successfully!");
          return {
            email: data?.user?.email,
            name: data?.user?.name,
            subscriber: true,
          };
        }
        localStorage.setItem("accessToken", data.accessToken);
        toast.error("Subscription not found!");
        return { email: user?.user?.email, name: user?.user?.displayName };
      } else {
        toast.error("Invalid credentials!");
        return false;
      }
    }
  } catch (error) {
    console.log(error, "Error in googleAuth");
    toast.error("Something went wrong!");
    return false;
  }
};
export const handleAppleAuth = async (type) => {
  try {
    const user = await signInWithPopup(auth, appleAuthprovider);
    console.log(user);
    if (type === "signup") {
      const { data, status } = await instance.post("/user/signup", {
        name: user?.user?.displayName,
        email: user?.user?.email,
        uid: user?.user?.uid,
      });
      if (status === 200) {
        localStorage.setItem("accessToken", data.accessToken);
        toast.success("Signed up successfully!");
        return { email: user?.user?.email, name: user?.user?.displayName };
      }
    } else {
      const { data, status } = await instance.post("/user", {
        uid: user?.user?.uid,
      });
      if (status === 200) {
        if (data?.user?.subscriptionActive) {
          localStorage.setItem("accessToken", data.accessToken);
          toast.success("Logged in successfully!");
          return true;
        }
        localStorage.setItem("accessToken", data.accessToken);
        toast.error("Subscription not found!");
        return { email: user?.user?.email, name: user?.user?.displayName };
      } else {
        toast.error("Invalid credentials!");
        return false;
      }
    }
  } catch (error) {
    console.log(error, "Error in appleAuth");
    toast.error("Something went wrong!");
    return false;
  }
};

export const handleForgotPassword = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (error) {
    console.error("Error sending password reset email:", error.message);
  }
};

export const handleResetPassword = async (oobCode, newPassword) => {
  try {
    await confirmPasswordReset(auth, oobCode, newPassword);
  } catch (error) {
    console.error("Error resetting password:", error);
    alert("Error resetting password. Please try again.");
  }
};
